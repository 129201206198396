import {createContext, useState, useEffect} from 'react';
import localStorage from "../utils/localStorage";

export const UserContext = createContext({
    user: {
        userData: {},
        isAuthenticated: false,

    },
    updateUserProfile: () => {},
    profileUpdateRequest: () => {},
    login: () => {
    },
    logout: () => {
    },
});

const INITIAL_STATE = {
    userData: {},
    token: null,
    isAuthenticated: false,
};

const initialState = () => {
    const userData = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (userData) {
        return {
            ...userData,
            token,
            isAuthenticated: true
        };
    }
    return INITIAL_STATE;
}

export const UserProvider = ({children}) => {
    const [user, setUser] = useState(initialState);

    const login = (data) => {
        localStorage.setItem('user', data.user);
        localStorage.setItem('token', data.access_token);
        setUser({
            ...data.user,
            token: data.access_token,
            isAuthenticated: true
        });
    };

    const updateUserProfile = (userData) => {
        setUser({
            ...userData
        });
        localStorage.setItem('user',userData);
    };

    const profileUpdateRequest = (data) => {
        const newData = {...data};
        newData.token = user.access_token;
        newData.isAuthenticated = true;
        setUser({...newData});
        localStorage.setItem('user',newData);
    };


    const logout = () => {
        setUser({...INITIAL_STATE});
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    };


    return <UserContext.Provider value={{user, updateUserProfile,profileUpdateRequest, logout, login}}>{children}</UserContext.Provider>;
};